@import "../shared";

.root {
    max-width: 1280px;
    margin: 0 auto;
    flex-direction: column;
    width: 100%;
    padding: 0 2px;
}

.galleryRoot {
    width: 100%;
    margin-top: 160px;
    padding: 0 50px;
    height: 430px;
    margin-bottom: 160px;

    .carousel {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .item {
        position: absolute;
        top: 0;
        text-align: center;
        transform: scale(0.2);
        transition: 0.4s;
        transform-origin: center;
        z-index: 0;
        opacity: 0.8;
        user-select: none;
    }

    .carouselImage {
        max-height: 430px;
        width: auto;
        box-shadow: 0 34px 80px 0 #00000014;
        //filter: drop-shadow(0px 34px 80px rgba(0, 0, 0, 0.08));
        border-radius: 8px;
        padding: 2px;
        user-select: none;
        pointer-events: none;
        background: transparent;
        transition: 0.3s;
        max-width: 100%;
        height: auto;
    }

    .itemNext {
        transform: translateX(200px) scale(0.83);
        z-index: 1;
    }

    .itemPrev {
        transform: translateX(-200px) scale(0.83);
        z-index: 1;
    }

    .itemActive {
        transform: none;
        z-index: 2;
        opacity: 1;

        .carouselImage {
            background: linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
        }
    }

    .prevBtn,
    .nextBtn {
        position: absolute;
        right: 100%;
        width: 50px;
        top: 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        @include max(1024) {
            display: none;
        }
    }

    .nextBtn {
        transform: rotateY(180deg);
        left: 100%;
        right: 0;
    }

    @include max(1024) {
        margin-top: 60px;
        padding: 0 34px;
        height: auto;
        margin-bottom: 60px;

        .itemPrev {
            transform: translateX(-35px) scaleY(0.83);
            transform-origin: left;
        }
        .itemNext {
            transform: translateX(35px) scaleY(0.83);
            transform-origin: right;
        }
    }
}

.reviewsRoot {
    width: 48%;
    max-width: 620px;
    margin-top: 10px;
    padding: 0 50px;
    min-height: 355px;

    .carousel {
        position: relative;
        display: flex;
        justify-content: center;
    }

    .item {
        position: absolute;
        top: 0;
        text-align: left;
        transform: scale(0.2);
        transition: 0.4s;
        transform-origin: center;
        z-index: 0;
        opacity: 0.8;
        user-select: none;
        background-color: #3b3060;
        box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 30px 65px;
        min-height: 100%;
    }

    .itemNext {
        transform: translateX(100px) scale(0.83);
        z-index: 1;
        background-color: #30254c;
    }

    .itemPrev {
        transform: translateX(-100px) scale(0.83);
        z-index: 1;
        background-color: #30254c;
    }

    .itemActive {
        transform: none;
        z-index: 2;
        opacity: 1;

        .arrowRight {
            transform: rotateY(180deg);
        }
    }

    .dotItem {
        margin: 0 12px;
        background: #3f3186;
        width: 12px;
        height: 12px;
        border-radius: 100%;
        cursor: pointer;
        box-shadow: 0 4px 9px rgba(0, 0, 0, 0.13);
    }

    .dotItemRoot {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 100%;
        padding-top: 22px;
    }

    .dotItemActive {
        background: #fff;
    }

    .userImage {
        height: 90px;
        width: auto;
        border-radius: 8px;
        user-select: none;
        pointer-events: none;
        background: transparent;
        transition: 0.3s;
        margin-right: 30px;
    }

    .title {
        display: flex;
        font-family: var(--font-poppins);
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.0583333px;
        color: #ffffff;
        margin-bottom: 30px;
    }

    .stars {
        margin-top: 13px;

        :global(.star-item) {
            margin-right: 9px;
        }
    }

    .arrowRight {
        position: absolute;
        right: 30px;
        top: 30px;
        transform: rotateY(180deg) scale(0);
        width: 28px;
        height: 28px;
        transition: 0.5s;
    }

    .reviewsDesc {
        font-family: var(--font-poppins);
        font-size: 16px;
        line-height: 22px;
        letter-spacing: 0.0583333px;
        margin-bottom: 0;
        display: flex;
        flex-direction: column;
    }
    .linkWrapper {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
    }
    .readMoreLink {
        color: #fff;
        margin-left: auto;
        font-family: var(--font-poppins);
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
        letter-spacing: 0.17px;
        text-decoration: underline;
    }

    @include max(1200) {
        padding: 0 30px;

        .itemNext {
            transform: translateX(70px) scale(0.83);
        }
        .itemPrev {
            transform: translateX(-70px) scale(0.83);
        }
    }
    @include max(1024) {
        margin: auto;
        order: 1;
        padding: 0 0 34px;
        width: 500px;
        max-width: 100%;

        .title {
            font-size: 18px;
            line-height: 28px;
        }

        .arrowRight {
            right: 8px;
        }
        .item {
            padding: 16px 37px 16px 20px;
        }
        .userImage {
            margin-right: 16px;
        }
        .itemNext {
            transform: translateX(0) scale(0.5);
        }
        .itemPrev {
            transform: translateX(0) scale(0.5);
        }
    }
    @include max(374) {
        .userImage {
            width: 45px;
            height: 45px;
        }
    }
}

.reviewWrapper {
    display: flex;
    flex-wrap: wrap;
    padding-top: 62px;
    justify-content: space-between;

    @include max(1024) {
        flex-direction: column;
    }
}

.activitiesRoot {
    width: 48%;
    max-width: 620px;
    background: #30254c;
    box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 25px 35px;
    display: flex;
    flex-direction: column;

    .title {
        font-family: var(--font-poppins);
        font-size: 24px;
        line-height: 19px;
        letter-spacing: 0.0583333px;
        color: #ffffff;
        margin-bottom: 22px;
    }

    .bg {
        min-height: 150px;
        border-radius: 20px;
        background-size: cover;
        padding: 23px 29px;
        margin-bottom: 22px;
    }

    .bgTitle {
        font-family: var(--font-poppins);
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.0583333px;
        color: #ffffff;
        margin-bottom: 2px;
    }

    .text {
        max-width: 262px;
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.47px;
        color: #cfcfcf;
    }

    .bottomPart {
        display: flex;
    }

    .img {
        margin-right: 39px;
        margin-bottom: auto;
        border-radius: 8px;
    }

    .previewTitle {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.47px;
        margin-bottom: 6px;
    }

    .previewDesc {
        font-size: 12px;
        line-height: 17px;
        letter-spacing: 0.47px;
        color: #cfcfcf;
    }

    @include max(1024) {
        width: 100%;
        margin: 0 auto 60px;
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        max-width: 500px;

        .title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 20px;
        }

        .img {
            margin-right: 17px;
        }

        .bg {
            padding: 18px;
            min-height: 185px;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
        }
    }
}

.partnerRoot {
    margin-bottom: 80px;

    .partnerHead {
        display: flex;
        justify-content: flex-start;
        font-family: var(--font-poppins);
        font-style: normal;
        font-weight: normal;
        font-size: 24px;
        line-height: 19px;
        letter-spacing: 0.0583333px;
        color: #ffffff;

        > div {
            position: relative;
            padding-bottom: 8px;
            margin-right: 30px;
            cursor: pointer;

            &:after {
                top: 100%;
                left: 0;
                position: absolute;
                background: transparent;
                border-radius: 9px;
                height: 4px;
                width: 100%;
                content: "";
                transition: 0.3s;
            }
        }

        .activeHead:after {
            background: #cc78ff;
        }
    }

    .overPartnerImg,
    .partnerImage {
        max-width: 100%;
        height: auto;
        width: 100%;
        display: block;
    }

    @include max(1024) {
        margin-top: 60px;

        .partnerHead {
            font-size: 18px;
            line-height: 19px;
            > div {
                margin-right: 24px;
            }
        }
    }
    @include max(575) {
        .overPartnerImg {
            width: 100vw;
            max-width: none;
            margin-left: -16px;
            margin-top: 20px;
        }
        .partnerHead {
            font-size: 16px;
            line-height: 20px;
            > div {
                margin-right: 16px;
            }
        }
    }
}

.offerRoot {
    margin-top: 160px;
    margin-bottom: 160px;
    background: #30254c;
    border-radius: 8px;
    padding: 60px 105px;
    display: flex;

    .left {
        max-width: 500px;
        margin-right: auto;
    }

    .offerTitle {
        font-family: var(--font-lora);
        font-size: 40px;
        line-height: 47px;
        letter-spacing: 0.0583333px;
        color: #ffffff;
        margin-bottom: 16px;
    }
    .offerDesc {
        font-size: 16px;
        line-height: 140%;
        letter-spacing: 0.0583333px;
        color: #cfcfcf;
        margin-bottom: 40px;
    }
    .button {
        background: #9b00fb;
        box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
        border-radius: 8px;
        border: none;
        padding: 18px 50px;
        cursor: pointer;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        transition: 0.3s;

        &:hover {
            background: #cc78ff;
        }
    }
    @include max(1024) {
        margin-top: 40px;
        padding: 0;
        background-color: transparent;
        margin-bottom: 137px;
        .offerTitle {
            font-size: 24px;
            line-height: 47px;
            margin-bottom: 12px;
        }
        .offerDesc {
            font-size: 12px;
            line-height: 140%;
            margin-bottom: 24px;
            max-width: 250px;
        }

        .button {
            padding: 9px 42px;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }
    }
}

.resortFinancingTitle {
    color: #fff;
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.058px;
    display: flex;
    gap: 38px;
    white-space: nowrap;
    align-items: center;
    margin-bottom: 40px;

    .line {
        height: 1px;
        flex-grow: 1;
        background: linear-gradient(90deg, #9e00ff 9.78%, rgba(158, 0, 255, 0) 87.17%);
    }
}
.resortFinancing {
    display: grid;
    grid-template-columns: 370px 1fr;
    grid-template-rows: 1fr 125px;
    margin-bottom: 128px;
    gap: 16px;
    grid-template-areas:
        "vote chart"
        "dues chart";

    .vote {
        grid-area: vote;
        border-radius: 8px;
        background: #3b3060;
        padding: 9px 15px;
        width: 370px;
        max-width: 100%;
        display: flex;
        flex-direction: column;
    }
    .duesTitle {
        border-radius: 12px;
        background: #3B3060;
        color: #FFF;
        font-size: 14px;
        font-weight: 400;
        line-height: 26.239px;
        padding: 3px 7px;
        margin-bottom: 11px;
    }

    .chart {
        grid-area: chart;
    }
    .chartInject {
        margin: 0;
        width: 100%;
        left: 0;
        transform: none;
        max-width: 100%;
    }

    .props {
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 17px 1fr 99px;
        gap: 5px 16px;
        padding-left: 13px;

        + .props {
            margin-top: 5px;
        }
    }

    .link {
        margin-left: auto;
        color: #979797;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.058px;
        text-decoration: underline;
        margin-top: auto;
        margin-bottom: 6px;
    }

    .voteTitle {
        color: #fff;
        font-size: 23.605px;
        font-style: normal;
        font-weight: 400;
        line-height: 130.023%; /* 30.692px */
    }
    .circleContainer {
        display: flex;
        justify-content: center;
        position: relative;
        margin-top: 10px;
        margin-bottom: 19px;
    }
    .circleRoot {
        width: 108px;
        height: 108px;
        transform-origin: center;
        transform: rotate(268deg);
        circle {
            stroke-width: 30;
            r: 175;
        }
    }
    .circle {
        transition: stroke-dashoffset 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    }
    .circleText {
        position: absolute;
        margin: auto;
        width: 108px;
        height: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: #fff;
        text-align: center;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 33px;
        letter-spacing: 0.058px;
        span {
            font-size: 12px;
            font-weight: 500;
            line-height: 19px;
            letter-spacing: 0.058px;
        }
    }
    .cell0 {
        width: 17px;
        color: #cc78ff;
        font-size: 20px;
        font-style: normal;
        text-align: center;
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.664px;
    }
    .dues {
        grid-area: dues;
        border-radius: 8px;
        background: #30254c;
        padding: 22px 12px 38px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .line {
            position: relative;
            background: #FFE4A0;
            height: 4px;
            .min {
                position: absolute;
                background: #38FFAB;
                height: 4px;
                width: var(--percentMin);
                top: 0;
                left: 0;
                &:before {
                    content: attr(data-value);
                    position: absolute;
                    top: 100%;
                    left: 100%;
                    transform: translateX(-50%);
                }
            }
            .value {
                left: var(--currentValue);
                width: 2px;
                height: 6px;
                top: -1px;
                background: #cc78ff;
                border: 2px solid #cc78ff;;
                border-radius: 1px;
                position: absolute;
            }
            .arrow {
                left: var(--currentValue);
                content: "";
                position: absolute;
                bottom: 100%;
                transform: translateX(-50%);
                margin-left: 2px;
                width: 11.726px;
                height: 13px;
            }
            .max {
                width: var(--percentMax);
                position: absolute;
                top: 0;
                right: 0;
                height: 4px;
                background: #FE5A9F;
                &:before {
                    content: attr(data-value);
                    position: absolute;
                    top: 100%;
                    right: 100%;
                    transform: translateX(50%);
                }
            }
        }
    }
    @include max(1024) {
        grid-template-columns: auto;
        grid-template-areas:
            "vote"
            "dues"
            "chart";
        .vote {
            width: 100%;
            flex-direction: row;
            flex-wrap: wrap;
            max-width: 700px;
            margin: 0 auto;
            justify-content: space-evenly;
            gap: 16px;
            > * {
                order: 3;
            }
            .props {
                order: 2;
            }
            .voteTitle {
                width: 100%;
                order: 1;
            }
            .link {
                text-align: right;
                width: 100%;
            }
        }
        .dues, .chart {
            width: 100%;
            max-width: 700px;
            margin: 0 auto;
        }
        .props {
            grid-template-columns: 17px 1fr minmax(70px, auto);
        }
    }
}
