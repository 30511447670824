@import "../shared";

.container {
	margin-left: -50px;
	margin-right: -50px;
	background: linear-gradient(180deg, #563E65 0%, #2F237A 85.54%);

	@include max($mobileSwitch) {
		margin-left: -16px;
		margin-right: -16px;
	}
}

.questionButton {
	background: linear-gradient(147.09deg, #C05BFE 8.42%, #A259FF 113.01%);
	border-radius: 8px;
	border: none;
	position: fixed;
	bottom: 30px;
	right: 18px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 55px;
	height: 55px;
	z-index: 20;
}

.cursorLight {
	width: 3px;
	height: 3px;
	box-shadow: 0 0 164px 147px rgba(255, 255, 255, 0.05);
	background-color: #ffffff4d;
	position: fixed;
	left: 5px;
	top: 5px;
	z-index: 20;
	border-radius: 100%;
}

.titleLevel2 {
	font-family: var(--font-lora);
	font-style: normal;
	font-weight: 500;
	font-size: 40px;
	line-height: 51px;
	letter-spacing: -0.02em;
	color: #FFFFFF;
	width: 100%;

	@include max($mobileSwitch) {
		font-size: 18px;
		line-height: 23px;
	}
}

.switchToNextBlockBtn {
	top: 60px;
	position: absolute;
	left: 20px;
	z-index: 13;
}

.block1Container {
	height: 100vh;
	position: relative;
	background: linear-gradient(164deg, #372970 36.88%, #F1ABDE 95.53%);
	min-height: 768px;

	@include max($mobileSwitch) {
		min-height: 568px;
	}

	.stars {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.starsIntersection {
		position: absolute;
		z-index: 2;
		top: 0;
		min-height: 50vh;
		width: 100%;
		left: 0;
	}

	img {
		user-select: none;
	}
}

.block1LayerWrapper {
	--translate: translateX(50%);
	position: absolute;
	width: 1440px;
	top: 0;
	bottom: 0;
	right: 50%;
	transform: var(--translate) scale(var(--scaleHome, 1));
	perspective: 200px;
	perspective-origin: bottom;
    transform-origin: bottom center;

	@include max($mobileSwitch) {
		--translate: translateX(30%);
	}


	.player {
		position: absolute;
		left: 0;
		z-index: 5;
		width: 375px;
		height: 375px;
		right: 0;
		bottom: 280px;
		transform: translate(378px, 0);
	}
}

.block1Layer6 {
	background: linear-gradient(166.12deg, #352473 25.52%, #F1ABDE 107.41%);
	position: absolute;
	width: 100%;
	left: 0;
	top: 0;
	height: 100%;
	z-index: 0;
}

/*
.block1Layer5 {
	position: absolute;
	left: 50%;
	width: 100%;
	height: 100%;
	top: 0;
	right: 0;
	margin: auto;
	transform: translate(-50%);
	z-index: 4;
	max-height: 900px;
	background: url("/images/homePage/block1/layer5.png") no-repeat top;
	background-size: contain;
	will-change: transform;

	@include max($mobileSwitch) {
		background: url("/images/homePage/block1/mobileBg.png") repeat top/auto, linear-gradient(164.39deg, #372970 30.85%, #F1ABDE 95.53%);
		left: 40%;
		right: 0;
		transform: translate(0);
		width: 110vw;
		height: 100vmax;
		margin: auto;
		top: 0;
		max-height: none;
	}
}*/


.block1Layer4 {
	background: url("/images/homePage/block1/layer4.png") no-repeat;
	position: absolute;
	width: 962px;
	left: 0;
	bottom: 217px;
	height: 263px;
	z-index: 7;
	will-change: transform;

	@include max($mobileSwitch) {
		display: none;
	}
}

.block1Layer3 {
	background: url("/images/homePage/block1/layer3.png") no-repeat bottom right/ contain;
	position: absolute;
	width: 100%;
	left: 0;
	bottom: -3px;
	height: 100%;
	z-index: 8;

	@include max($mobileSwitch) {
		bottom: -120px;
	}
}

.block1Layer2 {
	position: absolute;
	left: 50%;
	bottom: -44px;
	right: 0;
	margin: auto;
	transform: translate(-50%);
	z-index: 9;

	@include max($mobileSwitch) {
		display: none;
	}
}

.block1Layer1 {
	position: absolute;
	left: 50%;
	bottom: -64px;
	right: 0;
	margin: auto;
	transform: translate(-50%);
	z-index: 10;
	@include max($mobileSwitch) {
		display: none;
	}
}

.block1TextContent {
	position: relative;
	width: 1440px;
	max-width: 100%;
	padding: 172px 80px 0;
	z-index: 11;
	margin: auto;

	@include max($mobileSwitch) {
		padding: 97px 18px 0;
		width: 100vw;
	}

	@include max(375) {
		padding-top: 80px;
	}

	h1 {
		font-family: var(--font-lora);
		font-style: normal;
		font-weight: 500;
		font-size: 40px;
		line-height: 50px;
		letter-spacing: -0.02em;
		color: #FFFFFF;
		width: 557px;
		margin: 0;
		max-width: 100%;

		@include max($mobileSwitch) {
			max-width: 100%;
			line-height: 46px;
			letter-spacing: -0.02em;
			font-size: 36px;
			font-style: normal;
			font-weight: 500;
		}
	}

	p {
		margin-top: 24px;
		font-style: normal;
		font-weight: 500;
		font-size: 16px;
		line-height: 140%;
		color: #DBDBDB;
		width: 510px;
		margin-bottom: 53px;

		@include max($mobileSwitch) {
			max-width: 287px;
			font-size: 14px;
			margin-bottom: 18px;
		}
		@include max(375) {
			margin-top: 12px;
		}
	}

	.viewListings {
		display: inline-flex;
		align-items: center;
		cursor: pointer;
		padding: 12px 18px;
		box-sizing: border-box;
		border-radius: 8px;
		font-size: 18px;
		color: #FFFFFF;
		transition: background-color .3s;
		background-color: #9E00FF;
		box-shadow: 0 15px 41px rgba(0, 0, 0, 0.18);
		border: none;
		font-weight: 500;
		line-height: 1;
		letter-spacing: 0.5px;
		max-width: 320px;
		text-align: center;
		min-width: 180px;

		&:hover, &:active, &:focus {
			background-color: #CC78FF;
			color: #FFFFFF;
		}
	}
	.buttonsRow {
		display: inline-flex;
		gap: 16px;
		margin-top: 16px;
		flex-wrap: wrap;

		.selectResortTotalsWrap {
			border: 2px solid #cc78ff;
			display: flex;
			border-radius: 8px;
			position: relative;
			overflow:hidden;
			white-space:nowrap;
			text-overflow:ellipsis;
			min-width: 202px;
			max-width: 270px;

			svg {
				flex-shrink: 0;
				position: absolute;
				right: 3px;
				top: 50%;
				transform: translateY(-50%);
			}
		}

		.selectResortTotals {
			overflow:hidden;
			max-width: 100%;
			white-space:nowrap;
			text-overflow:ellipsis;
			flex-grow: 1;
			z-index: 1;
			cursor: pointer;
			height: 42px;
			background: transparent;
			color: #fff;
			font-weight: 500;
			font-size: 18px;
			border: none;
			letter-spacing: .5px;
			text-align: center;
			padding: 0 32px 0 16px;
			appearance: none;
			-webkit-padding-end: 30px !important;
			-webkit-padding-start: 10px !important;

			&:hover, &:active, &:focus, &:focus-visible {
				outline: none;
			}
			option {
				overflow:hidden;
				white-space:nowrap;
				text-overflow:ellipsis;
				cursor: pointer;
				background-color: #423072;
				color: #fff;
				text-align: left;
				padding: 20px;

				&:checked  {
					font-weight: 400;
					background-color: #cc78ff;
				}
			}
		}
	}
}

.gradientContainer {
	background: linear-gradient(179deg, #6A4661 4.4%, #453686 15%, #563E65 17.96%, #453686 95.96%);

	@include max($mobileSwitch) {
		background: linear-gradient(189.3deg, #6A4661 10.4%, #453686 92.96%);
		position: relative;
		box-shadow: 3px 0 6px 2px #6a4661;
	}
}

.block2container {
	position: relative;
	padding-top: 924px;

	@include max($mobileSwitch) {
		padding-top: 120px;
	}
}

.block2LayerWrapper {
	position: absolute;
	width: 1440px;
	height: 858px;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	z-index: 11;
}

.block2Layer1 {
	position: absolute;
	top: -97px;
	right: -511px;
	margin: auto;
	z-index: 10;
}

.block2Layer2 {
	position: absolute;
	right: -475px;
	top: -190px;
	margin: auto;
	z-index: 11;
}
@include min(1800) {
	.block2Layer1,
	.block2Layer2 {
		zoom: 1.15;
	}
}

.block2Layer3 {
	position: absolute;
	right: -440px;
	top: 395px;
	z-index: 12;
}

.block2Layer4 {
	position: absolute;
	right: -143px;
	top: 382px;
	z-index: 13;
}

.block2Layer5 {
	position: absolute;
	right: -185px;
	top: 403px;
	z-index: 14;
}

.block2Layer6 {
	position: absolute;
	left: -50px;
	top: 493px;
	z-index: 15;
}

.block2Layer7 {
	position: absolute;
	left: -252px;
	top: 601px;
	z-index: 11;
}

.block2Layer1mobile {
	position: absolute;
	top: -25px;
	z-index: 6;
	left: -62px;
}

.block2Layer2mobile {
	position: absolute;
	top: -62px;
	z-index: 5;
	right: -80px;
}

.block2Layer3mobile {
	position: absolute;
	top: -40px;
	z-index: 4;
	left: -57px;
}

.block2Layer4mobile {
	position: absolute;
	top: -80px;
	right: -80px;
	z-index: 3;
}

.block2Layer5mobile {
	position: absolute;
	top: -50px;
	left: -15px;
	z-index: 2;
}

.block2Layer6mobile {
	position: absolute;
	top: -60px;
	right: -36px;
	z-index: 1;
}


.block2MobileLayer1 {
	margin-top: 100px;
	position: relative;
	left: 50%;
	transform: translateX(-40%);
	z-index: 14;
}

.block2TextContent {
	position: relative;
	z-index: 12;
	padding-top: 73px;
	display: flex;
	flex-wrap: wrap;
	padding-left: 80px;
	padding-right: 80px;
	justify-content: space-between;
	width: 1440px;
	margin: auto;
	max-width: 100vw;

	.stars {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.starsIntersection {
		position: absolute;
		z-index: 2;
		top: calc(100% - 45px);
		height: 200px;
		width: 100%;
		left: 0;
	}

	@include max($tabletSwitch) {
		padding: 22px;
	}

	h2 {
		margin-bottom: 52px;
	}

	.block2TextContentGroup {
		display: flex;
		justify-content: space-between;

		@include max($tabletSwitch) {
			flex-direction: column;
			margin: auto;
		}
	}

	.block2TextBlock {
		background-color: rgba(255, 255, 255, 0.12);
		backdrop-filter: blur(32px);
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		flex-basis: 400px;
		padding: 36px;
		font-family: var(--font-poppins);
		font-style: normal;
		font-weight: normal;
		font-size: 24px;
		line-height: 36px;
		text-align: center;
		letter-spacing: -0.02em;
		color: #FFFFFF;
		position: relative;

		>	.starsIntersectionSmallLeft {
			position: absolute;
			z-index: 13;
			top: 40%;
			height: 250px;
			width: 125px;
			right: 100%;
			.stars {
				width: 100%;
				height: 100%;
			}
		}


		& + .block2TextBlock {
			margin-left: 40px;
		}


		svg {
			margin-bottom: 27px;
		}

		@include max(1300) {
			font-size: 20px;
			padding: 20px;

			& + .block2TextBlock {
				margin-left: 25px;
			}
			svg {
				max-height: 60px;
			}
		}

		@include max($tabletSwitch) {
			flex-basis: auto;
			width: 100%;
			flex-direction: row;
			margin: 0 0 15px;
			font-size: 12px;
			line-height: 18px;
			text-align: left;
			padding: 30px 20px;

			svg {
				margin-bottom: 0;
				margin-right: 26px;
				width: 42px;
				flex-shrink: 0;
				height: auto;
			}

			& + .block2TextBlock {
				margin-left: 0;
			}
		}
	}
}

.block2TextContent2 {
	width: 1440px;
	max-width: 100%;
	margin: auto;
	padding: 0 80px;

	h2 {
		margin-bottom: 28px;
	}

	p {
		font-size: 24px;
		line-height: 140%;
		letter-spacing: 0.47px;
		color: #CFCFCF;
		margin-bottom: 32px;
		max-width: 80%;
	}

	@include max($mobileSwitch) {
		padding: 0 20px;
		width: 100vw;

		h2 {
			margin-bottom: 16px;
		}
		p {
			font-size: 12px;
			line-height: 140%;
			letter-spacing: 0.47px;
			margin-bottom: 16px;
		}
	}
}


.resaleVsDirect {
	display: flex;
	padding-bottom: 150px;
	position: relative;

	@include max($tabletSwitch) {
		flex-wrap: wrap;
		padding-bottom: 100px;
	}

	> .starsIntersection {
		position: absolute;
		bottom: 0;
		height: 240px;
		width: 100%;
		left: 0;

		> .stars {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}

.resaleVsDirectLeft {
	width: 730px;
	max-width: 66%;
	margin-right: 30px;

	.select {
		margin-bottom: 40px;
		width: 100%;

		:global(.ant-select-selector) {
			background: #3B3060;
			box-shadow: inset 0 3px 4px rgb(0 0 0 / 15%);
			border-radius: 10px;
			color: #CFCFCF;
			border: none;
			height: 63px;
			padding: 14px 32px;
		}

		:global(.ant-select-selection-item) {
			font-size: 18px;
			line-height: 35px;
			letter-spacing: 0.47px;
		}

		:global(.ant-select-arrow) {
			width: 25px;
			height: 25px;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			right: 20px;
		}
	}

	@include max($tabletSwitch) {
		max-width: 100%;
		margin-bottom: 40px;
		margin-right: 0;
		width: 100%;

		.select {
			margin-bottom: 26px;

			:global(.ant-select-selector) {
				height: 53px;
				padding: 10px 40px 10px 19px;
			}

			:global(.ant-select-selection-item) {
				font-size: 14px;
				line-height: 33px;
			}
		}
	}
}

.resaleVsDirectRight {
	width: 400px;
	max-width: 33%;
	margin-left: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	position: relative;
	min-height: 555px;

	@include max($tabletSwitch) {
		max-width: 100%;
		margin-left: 0;
		width: 100%;
		display: flex;
		min-height: 0;
		flex-direction: row;
		justify-content: center;

		.overCircle {
			width: 160px;
			margin-right: 16px;
			min-width: 37%;
			margin-left: auto;
		}
	}

	.stars {
		width: 100%;
		top: -20%;
		height: 140%;
		position: absolute;
		left: 0;
	}

	.starsIntersection {
		position: absolute;
		top: 0;
		height: 100%;
		width: 100%;
		left: 0;
	}
}

.resaleVsDirectRightText {
	position: absolute;
	left: 0;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	font-weight: 300;
	font-size: 64px;
	text-align: center;
	width: 235px;
	color: #FFFFFF;
	margin: auto;

	div {
		font-size: 24px;
		line-height: 43.2px;
		letter-spacing: 0.88px;
	}

	@include max($tabletSwitch) {
		font-size: 24px;
		line-height: 36px;
		width: 116px;

		div {
			font-size: 12px;
			line-height: 21.6px;
		}
	}
}

.resaleVsDirectResort {
	position: relative;
	display: flex;
	flex-wrap: wrap;

	&:before {
		content: "";
		position: absolute;
		left: 1px;
		right: 1px;
		bottom: 155px;
		top: 56px;
		background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
	}

	> img {
		width: 100%;
		height: auto;
		border: 1px solid #fff;
		border-bottom: 0;
		border-radius: 16px 16px 0 0;
	}

	.baseOn {
		font-size: 10px;
		line-height: 140%;
		letter-spacing: 0.47px;
		color: #CFCFCF;
		width: 100%;
		left: 0;
		text-align: center;
		bottom: 13px;
		position: absolute;
	}

	.resaleVsDirectResortName {
		font-weight: 500;
		font-size: 40px;
		line-height: 117%;
		position: absolute;
		letter-spacing: 0.664021px;
		color: #FFFFFF;
		bottom: 180px;
		left: 30px;
		width: 373px;
	}

	.resaleVsDirectResortBtn {
		width: 50%;
		z-index: 1;
		background-color: rgba(255, 255, 255, 0.12);
		backdrop-filter: blur(32px);
		height: 154px;
		border: 1px solid;
		border-image-slice: 1;
		border-image-source: linear-gradient(180deg, #FFFFFF 0%, rgba(0, 0, 0, 0) 100%);
		display: flex;
		flex-direction: column;
		align-items: center;
		font-size: 40px;
		line-height: 43px;
		letter-spacing: 0.664021px;
		color: #E2E2E2;
		padding: 24px;
		font-family: var(--font-poppins);
		border-bottom-left-radius: 8px;

		& + .resaleVsDirectResortBtn {
			background-color: rgba(255, 255, 255, 0.33);
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 8px;
		}

		span {
			font-size: 24px;
			line-height: 40px;
			color: #FFFFFF;
			margin-bottom: 11px;
		}
	}

	@include max($tabletSwitch) {
		&:before {
			bottom: 96px;
			top: 28px;
		}

		> img {
			height: auto;
			object-fit: cover;
			object-position: top;
		}
		.baseOn {
			bottom: 8px;
		}

		.resaleVsDirectResortBtn {
			height: 96px;
			font-size: 18px;
			line-height: 43px;
			padding: 10px;

			> img {
				width: 63px;
				height: auto;
			}

			span {
				font-size: 14px;
				line-height: 26px;
				margin-bottom: 0;
			}
		}
	}
}

.overCircle {
	position: relative;
	z-index: 2;
}

.circleRoot {
	transform-origin: center;
	transform: rotate(273deg);
	width: 100%;
	height: auto;
}

.circle {
	transition: stroke-dashoffset 2s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

/*.circleLight {
	position: absolute;
	width: 220px;
	height: 220px;
	top: 50%;
	left: 50%;
	!*background: #F0FBC7;
	filter: blur(150px);*!
	border-radius: 100%;
	transform: translate(-50%, -50%);
}*/

.subCircleText {
	font-size: 24px;
	line-height: 46px;
	letter-spacing: 0.704783px;
	color: #B964FB;
	text-align: center;
	margin-top: 32px;
	position: relative;
	z-index: 2;

	div {
		font-size: 40px;
		line-height: 46px;
		margin-top: 11px;
		letter-spacing: 0.704783px;
		color: #E0E0E0;
	}

	@include max($tabletSwitch) {
		font-size: 18px;
		line-height: 18px;
		text-align: left;
		margin-left: auto;
		margin-right: auto;

		div {
			font-size: 24px;
		}
	}
}

.block3container {
	width: 1440px;
	max-width: 100%;
	margin: 0 auto 115px;
	padding: 0 80px;
	position: relative;

	.stars {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	.starsIntersection {
		position: absolute;
		bottom: 0;
		min-height: 25%;
		width: 100%;
		left: 0;
	}

	h2 {
		margin-bottom: 40px;
	}

	.slideContent {
		width: 863px;
		height: 440px;
		margin: auto;
		position: relative;
		max-width: 100%;

		&:before {
			content: "";
			position: absolute;
			z-index: 1;
			background-image: linear-gradient(180deg, rgba(255, 255, 255, 0) 21.78%, rgba(43, 100, 171, 0.68) 83.74%);
			opacity: 0;
			bottom: 0;
			left: 0;
			right: 0;
			height: 315px;
			transition: opacity .5s;
			border-radius: 8px;
		}

		&:after {
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			border-radius: 8px;
			background-image: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.7) 100%, #B9B9B9 100%);
			content: "";
			z-index: -1;
			transition: opacity 0.3s;
		}

		img {
			width: 100%;
			height: 100%;
			border-radius: 8px;
			//filter: drop-shadow(0px 34px 80px rgba(0, 0, 0, 0.08)); case lags on iOS
			pointer-events: none;
		}

		.slideContentText {
			position: absolute;
			left: 32px;
			bottom: 32px;
			right: 32px;
			z-index: 2;
			font-family: 'Poppins', sans-serif;

			h3 {
				font-weight: 500;
				font-size: 24px;
				line-height: 36px;
				letter-spacing: 0.47px;
				color: #FFFFFF;
				margin-bottom: 4px;
			}

			p {
				font-size: 16px;
				line-height: 140%;
				letter-spacing: 0.47px;
				color: #FFFFFF;
				margin-bottom: 0;
			}
		}
	}

	.itemActive {
		.slideContent:before {
			opacity: 1;
		}
	}

	.overBtn {
		margin-top: 60px;
		text-align: center;
		position: relative;
		z-index: 1;
	}

	@include max($mobileSwitch) {
		padding: 0 20px;
		max-width: 100vw;

		.slideContent {
			height: auto;
			width: 100%;

			&:before {
				content: none;
			}

			img {
				height: auto;
			}
		}
		.dotsRoot {
			position: absolute;
			left: 0;
			right: 0;
			top: calc(100% + 20px);
			transform: translateY(-50%);
			display: flex;
			justify-content: center;
		}

		.dotItem {
			box-shadow: 0 4px 9px rgba(0, 0, 0, 0.13);
			background-color: #3F3186;
			border-radius: 100%;
			width: 8px;
			height: 8px;
			margin: 0 8px;
			cursor: pointer;
		}

		.dotItemActive {
			background-color: #fff;
		}
		.slideContentText {
			padding-top: 40px;

			h3 {
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				letter-spacing: 0.47px;
				color: #FFFFFF;
				margin-bottom: 3px;
			}

			p {
				font-size: 12px;
				line-height: 140%;
				letter-spacing: 0.47px;
				color: #CFCFCF;
				margin: 0;
			}
		}
		.overBtn {
			margin-top: 35px;

			button {
				font-size: 14px;
				line-height: 21px;
				padding: 10px 50px;
			}
		}
	}
}

.carouselRoot {
	position: relative;
	min-height: 440px;
	perspective: 400px;
	backface-visibility: visible;
	perspective-origin: center;
	transform-style: preserve-3d;
	user-select: none;

	.item {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		transform: translate3d(0, 0, -150px);
		transition: .3s ease-in-out;
		width: 863px;
		max-width: 100%;
		will-change: transform;
	}

	.itemActive {
		transform: translate3d(0, 0, 0);
	}

	.itemNext {
		cursor: pointer;
		transform: translate3d(500px, 10px, -114px);
	}

	.itemPrev {
		cursor: pointer;
		transform: translate3d(-500px, 10px, -114px);
	}

	.navBtn {
		z-index: 5;
		border: none;
		width: 60px;
		height: 100%;
		font-size: 0;
		cursor: pointer;
		background-color: transparent;
		padding: 0;
		position: absolute;
		top: 0;
		left: 0;

		&:before {
			content: "";
			position: absolute;
			left: 50%;
			border-right: 2px solid white;
			border-bottom: 2px solid white;
			transform: translate(-50%, -50%) rotate(135deg);
			border-radius: 2px;
			width: 22px;
			height: 22px;
			top: 50%;
		}

		+ .navBtn {
			left: auto;
			right: -5px;

			&:before {
				left: auto;
				transform: translate(-50%, -50%) rotate(-45deg);
				right: 15px;
			}
		}
	}

	@include max($mobileSwitch) {
		min-height: auto;

		.item {
			max-width: 90vw;
			bottom: auto;
		}

		.itemNext {
			transform: translate3d(33%, 0, -114px);
		}

		.itemPrev {
			transform: translate3d(-33%, 0, -114px);
		}
	}
}

.block4container {
	width: 1440px;
	max-width: 100%;
	margin: 0 auto 60px;
	padding: 0 60px;

	h2 {
		margin-bottom: 60px;
	}

	.carouselRoot {
		min-height: 400px;
	}

	.slideContent {
		width: 777px;
		background-color: #392A66;
		box-shadow: 0 34px 80px rgba(0, 0, 0, 0.08);
		padding: 50px 50px 50px 40px;
		display: flex;
		align-content: stretch;
		justify-content: space-around;
		flex-wrap: nowrap;
		flex-direction: row;
		align-items: flex-start;
		transition: background-color .3s;
		border-radius: 8px;
		position: relative;
		max-width: 100%;

		&:before {
			position: absolute;
			top: -1px;
			bottom: -1px;
			left: -1px;
			right: -1px;
			background-image: linear-gradient(180deg, #FFFFFF -35.37%, rgba(255, 255, 255, 0) 76.83%);
			content: '';
			z-index: -1;
			border-radius: 8px;
			transition: opacity .3s;
			opacity: 0;
		}
	}

	.img {
		margin-right: 35px;
		position: relative;
		z-index: 1;
		pointer-events: none;
		border-radius: 100%;
	}

	.imgBlur {
		position: absolute;
		top: 50%;
		bottom: 0;
		left: 40px;
		margin: 20px 0 0;
		transform: translateY(-50%);
		z-index: 0;
		opacity: 0;
		filter: blur(59px);
		transition: opacity .3s;
		pointer-events: none;
	}

	.slideContentText, .img {
		opacity: 0;
		transition: .3s;
	}

	.item {
		width: 777px;
	}

	.itemActive {
		opacity: 1;

		.slideContent {
			background-color: #423072;

			&:before {
				opacity: 1;
			}
		}

		.slideContentText, img {
			opacity: 1;
		}

		.imgBlur {
			opacity: 0.29;
		}
	}

	.itemPrev {
		transform: translate3d(-26%, -4%, -60px);
	}

	.itemNext {;
		transform: translate3d(26%, -4%, -60px);
	}

	.dotsRoot {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
	}

	.dotItem {
		box-shadow: 0 4px 9px rgba(0, 0, 0, 0.13);
		background-color: #3F3186;
		border-radius: 100%;
		width: 12px;
		height: 12px;
		margin: 0 12px;
		cursor: pointer;
	}

	.dotItemActive {
		background-color: #fff;
	}

	.slideContentText {
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.47px;
		color: #CFCFCF;

		h3 {
			font-weight: 600;
			font-size: 24px;
			line-height: 140%;
			letter-spacing: 0.47px;
			color: #FFFFFF;
			margin-bottom: 0;
		}

		p {
			font-weight: 500;
			font-size: 18px;
			line-height: 140%;
			letter-spacing: 0.47px;
			color: #CC78FF;
			margin-bottom: 18px;
		}
	}

	@include max($mobileSwitch) {
		padding: 0 20px;
		h2 {
			margin-bottom: 16px;
		}

		.carouselRoot {
			min-height: 160px;
			box-sizing: content-box;
			padding-bottom: 24px;
		}
		.item {
			width: 100%;
			max-width: 500px;
			transition: opacity .3s, transform 0.3s;
		}
		.itemPrev, .itemNext {
			opacity: 0;
		}
		.slideContent {
			width: 100%;
			padding: 16px 12px;

			.img, .imgBlur {
				width: 62px;
				height: 62px;
				margin-right: 16px;
			}

			.imgBlur {
				transform: none;
				left: 12px;
				top: 16px;
				margin-top: 6px;
				filter: blur(59px);
			}
		}
		.slideContentText {
			font-size: 12px;
			line-height: 140%;

			h3 {
				font-size: 14px;
			}

			p {
				font-size: 12px;
				margin-bottom: 13px;
			}
		}
		.dotItem {
			width: 8px;
			height: 8px;
			margin: 0 8px;
		}
	}
}


.block5container {
	padding-left: 80px;
	padding-right: 80px;
	width: 1440px;
	margin: auto;
	max-width: 100%;
	padding-bottom: 65px;

	h2 {
		margin-bottom: 60px;
	}

	.list {
		display: flex;
		justify-content: space-around;
		flex-wrap: wrap;
		align-items: center;

		img {
			margin-bottom: 48px;
		}
	}

	@include max($mobileSwitch) {
		padding-left: 20px;
		padding-right: 20px;
		max-width: 100vw;
		.list {
			img {
				max-width: 214px;
				height: auto;
			}
		}
	}
}

.blogContainer {
	padding-left: 80px;
	padding-right: 80px;
	width: 1440px;
	margin: auto;
	max-width: 100%;
	padding-bottom: 300px;

	h2 {
		margin-bottom: 60px;
	}

	@include max($mobileSwitch) {
		padding-bottom: 160px;
		padding-left: 20px;
		padding-right: 20px;
		max-width: 100vw;
		h2 {
			margin-bottom: 16px;
		}
	}
}

.hiddenLabel {
	transform: scale(0);
	height: 0;
	width: 0;
	font-size: 0;
	line-height: 0;
}
